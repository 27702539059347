import { Injectable } from '@angular/core';
import { HTTP_AP_BulkApproveMortgageCases_Request } from '@api-new/caseservice/HTTP_AP_BulkApproveMortgageCases_Request';
import { HTTP_AP_CompanyMortgageCaseSettings } from '@api-new/caseservice/HTTP_AP_CompanyMortgageCaseSettings';
import { HTTP_AP_CreateMortgageCase_Request } from '@api-new/caseservice/HTTP_AP_CreateMortgageCase_Request';
import { HTTP_AP_GetCompanyMortgageCaseSettings_Request } from '@api-new/caseservice/HTTP_AP_GetCompanyMortgageCaseSettings_Request';
import { HTTP_AP_GetHelpCase_ForCaseDetail_Request } from '@api-new/caseservice/HTTP_AP_GetHelpCase_ForCaseDetail_Request';
import { HTTP_AP_GetMortgageCase_ForCaseDetail_Request } from '@api-new/caseservice/HTTP_AP_GetMortgageCase_ForCaseDetail_Request';
import { HTTP_AP_GetMortgageCase_ForCaseDetail_Response } from '@api-new/caseservice/HTTP_AP_GetMortgageCase_ForCaseDetail_Response';
import { HTTP_AP_GetSmartSearchCase_ForCaseDetail_Request } from '@api-new/caseservice/HTTP_AP_GetSmartSearchCase_ForCaseDetail_Request';
import { HTTP_AP_GetSmartSearchCase_ForCaseDetail_Response } from '@api-new/caseservice/HTTP_AP_GetSmartSearchCase_ForCaseDetail_Response';
import { HTTP_AP_HelpCase } from '@api-new/caseservice/HTTP_AP_HelpCase';
import { HTTP_AP_ListCases_ForApprovalRequiredTable_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForApprovalRequiredTable_Request';
import { HTTP_AP_ListCases_ForApprovalRequiredTable_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForApprovalRequiredTable_Response';
import { HTTP_AP_ListCases_ForClientDetail_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForClientDetail_Request';
import { HTTP_AP_ListCases_ForClientDetail_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForClientDetail_Response';
import { HTTP_AP_ListCases_ForClosedTable_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForClosedTable_Request';
import { HTTP_AP_ListCases_ForClosedTable_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForClosedTable_Response';
import { HTTP_AP_ListCases_ForLeadsTable_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForLeadsTable_Request';
import { HTTP_AP_ListCases_ForLeadsTable_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForLeadsTable_Response';
import { HTTP_AP_ListCases_ForOpportunitiesTable_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForOpportunitiesTable_Request';
import { HTTP_AP_ListCases_ForOpportunitiesTable_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForOpportunitiesTable_Response';
import { HTTP_AP_ListCases_ForPipelineTable_Request } from '@api-new/caseservice/HTTP_AP_ListCases_ForPipelineTable_Request';
import { HTTP_AP_ListCases_ForPipelineTable_Response } from '@api-new/caseservice/HTTP_AP_ListCases_ForPipelineTable_Response';
import { HTTP_AP_ListMortgageCases_WithStatusOpportunity_Request } from '@api-new/caseservice/HTTP_AP_ListMortgageCases_WithStatusOpportunity_Request';
import { HTTP_AP_ListMortgageCases_WithStatusOpportunity_Response } from '@api-new/caseservice/HTTP_AP_ListMortgageCases_WithStatusOpportunity_Response';
import { HTTP_AP_MortgageCase } from '@api-new/caseservice/HTTP_AP_MortgageCase';
import { HTTP_AP_UpdateCompanyMortgageCaseSettings_Request } from '@api-new/caseservice/HTTP_AP_UpdateCompanyMortgageCaseSettings_Request';
import { HTTP_AP_UpdateHelpCase_Request } from '@api-new/caseservice/HTTP_AP_UpdateHelpCase_Request';
import { HTTP_AP_UpdateMortgageCase_Request } from '@api-new/caseservice/HTTP_AP_UpdateMortgageCase_Request';
import { HTTP_AP_UpdateSmartSearchCase_Request } from '@api-new/caseservice/HTTP_AP_UpdateSmartSearchCase_Request';
import { HTTP_AP_ValidateBulkApproveMortgageCases_Response } from '@api-new/caseservice/HTTP_AP_ValidateBulkApproveMortgageCases_Response';
import { HTTP_CP_CreateHelpCase_Request } from '@api-new/caseservice/HTTP_CP_CreateHelpCase_Request';
import { HTTP_CP_CreateSmartSearchCase_Request } from '@api-new/caseservice/HTTP_CP_CreateSmartSearchCase_Request';
import { HTTP_CP_HelpCase } from '@api-new/caseservice/HTTP_CP_HelpCase';
import { HTTP_CP_ListHelpCases_Response } from '@api-new/caseservice/HTTP_CP_ListHelpCases_Response';
import { HTTP_CP_ListMortgageCases_Response } from '@api-new/caseservice/HTTP_CP_ListMortgageCases_Response';
import { HTTP_CP_ListSmartSearchCases_Response } from '@api-new/caseservice/HTTP_CP_ListSmartSearchCases_Response';
import { HTTP_CP_MortgageCase } from '@api-new/caseservice/HTTP_CP_MortgageCase';
import { HTTP_CP_SmartSearchCase } from '@api-new/caseservice/HTTP_CP_SmartSearchCase';
import { HTTP_CP_UpdateHelpCase_Request } from '@api-new/caseservice/HTTP_CP_UpdateHelpCase_Request';
import { HTTP_CP_UpdateMortgageCase_Request } from '@api-new/caseservice/HTTP_CP_UpdateMortgageCase_Request';
import { HTTP_CP_UpdateSmartSearchCase_Request } from '@api-new/caseservice/HTTP_CP_UpdateSmartSearchCase_Request';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class CaseApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_BulkApproveMortgageCases(request: HTTP_AP_BulkApproveMortgageCases_Request): Observable<undefined> {
    return this.httpClient.post(`/case-service/ap/mortgage-cases/all/BulkApprove`, request);
  }

  HTTP_AP_CreateMortgageCase(request: HTTP_AP_CreateMortgageCase_Request): Observable<HTTP_AP_MortgageCase> {
    return this.httpClient.post(`/case-service/ap/mortgage-case`, request);
  }

  HTTP_AP_GetCompanyMortgageCaseSettings(
    request: HTTP_AP_GetCompanyMortgageCaseSettings_Request,
  ): Observable<HTTP_AP_CompanyMortgageCaseSettings> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/case-service/ap/company-mortgage-case-settings/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_AP_GetHelpCase_ForCaseDetail(request: HTTP_AP_GetHelpCase_ForCaseDetail_Request): Observable<HTTP_AP_HelpCase> {
    const { helpCaseId, ...updatedRequest } = request;
    return this.httpClient.get(`/case-service/ap/help-cases/${request.helpCaseId}/ForCaseDetail`, { params: updatedRequest });
  }

  HTTP_AP_GetMortgageCase_ForCaseDetail(
    request: HTTP_AP_GetMortgageCase_ForCaseDetail_Request,
  ): Observable<HTTP_AP_GetMortgageCase_ForCaseDetail_Response> {
    const { mortgageCaseId, ...updatedRequest } = request;
    return this.httpClient.get(`/case-service/ap/mortgage-cases/${request.mortgageCaseId}/ForCaseDetail`, { params: updatedRequest });
  }

  HTTP_AP_GetSmartSearchCase_ForCaseDetail(
    request: HTTP_AP_GetSmartSearchCase_ForCaseDetail_Request,
  ): Observable<HTTP_AP_GetSmartSearchCase_ForCaseDetail_Response> {
    const { smartSearchCaseId, ...updatedRequest } = request;
    return this.httpClient.get(`/case-service/ap/smart-search-cases/${request.smartSearchCaseId}/ForCaseDetail`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_ListCases_ForApprovalRequiredTable(
    request: HTTP_AP_ListCases_ForApprovalRequiredTable_Request,
  ): Observable<HTTP_AP_ListCases_ForApprovalRequiredTable_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForApprovalRequiredTable`, { params: request });
  }

  HTTP_AP_ListCases_ForClientDetail(
    request: HTTP_AP_ListCases_ForClientDetail_Request,
  ): Observable<HTTP_AP_ListCases_ForClientDetail_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForClientDetail`, { params: request });
  }

  HTTP_AP_ListCases_ForClosedTable(
    request: HTTP_AP_ListCases_ForClosedTable_Request,
  ): Observable<HTTP_AP_ListCases_ForClosedTable_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForClosedTable`, { params: request });
  }

  HTTP_AP_ListCases_ForLeadsTable(request: HTTP_AP_ListCases_ForLeadsTable_Request): Observable<HTTP_AP_ListCases_ForLeadsTable_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForLeadsTable`, { params: request });
  }

  HTTP_AP_ListCases_ForOpportunitiesTable(
    request: HTTP_AP_ListCases_ForOpportunitiesTable_Request,
  ): Observable<HTTP_AP_ListCases_ForOpportunitiesTable_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForOpportunitiesTable`, { params: request });
  }

  HTTP_AP_ListCases_ForPipelineTable(
    request: HTTP_AP_ListCases_ForPipelineTable_Request,
  ): Observable<HTTP_AP_ListCases_ForPipelineTable_Response> {
    return this.httpClient.get(`/case-service/ap/cases/all/ForPipelineTable`, { params: request });
  }

  HTTP_AP_ListMortgageCases_WithStatusOpportunity(
    request: HTTP_AP_ListMortgageCases_WithStatusOpportunity_Request,
  ): Observable<HTTP_AP_ListMortgageCases_WithStatusOpportunity_Response> {
    return this.httpClient.get(`/case-service/ap/mortgage-cases/all/ForFindOpportunitiesAdminTool`, { params: request });
  }

  HTTP_AP_UpdateCompanyMortgageCaseSettings(
    request: HTTP_AP_UpdateCompanyMortgageCaseSettings_Request,
  ): Observable<HTTP_AP_CompanyMortgageCaseSettings> {
    return this.httpClient.put(`/case-service/ap/company-mortgage-case-settings/${request.companyId}`, request);
  }

  HTTP_AP_UpdateHelpCase(request: HTTP_AP_UpdateHelpCase_Request): Observable<undefined> {
    return this.httpClient.put(`/case-service/ap/help-cases/${request.helpCaseId}`, request);
  }

  HTTP_AP_UpdateMortgageCase(request: HTTP_AP_UpdateMortgageCase_Request): Observable<undefined> {
    return this.httpClient.put(`/case-service/ap/mortgage-cases/${request.mortgageCaseId}`, request);
  }

  HTTP_AP_UpdateSmartSearchCase(request: HTTP_AP_UpdateSmartSearchCase_Request): Observable<undefined> {
    return this.httpClient.put(`/case-service/ap/smart-search-cases/${request.smartSearchCaseId}`, request);
  }

  HTTP_AP_ValidateBulkApproveMortgageCases(
    request: HTTP_AP_BulkApproveMortgageCases_Request,
  ): Observable<HTTP_AP_ValidateBulkApproveMortgageCases_Response> {
    return this.httpClient.post(`/case-service/ap/mortgage-cases/all/ValidateBulkApprove`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_CreateHelpCase(request: HTTP_CP_CreateHelpCase_Request): Observable<HTTP_CP_HelpCase> {
    return this.httpClient.post(`/case-service/cp/help-cases`, request);
  }

  HTTP_CP_CreateSmartSearchCase(request: HTTP_CP_CreateSmartSearchCase_Request): Observable<HTTP_CP_SmartSearchCase> {
    return this.httpClient.post(`/case-service/cp/smart-search-cases`, request);
  }

  HTTP_CP_ListHelpCases(request?: undefined): Observable<HTTP_CP_ListHelpCases_Response> {
    return this.httpClient.get(`/case-service/cp/help-cases`, { params: request });
  }

  HTTP_CP_ListMortgageCases(request?: undefined): Observable<HTTP_CP_ListMortgageCases_Response> {
    return this.httpClient.get(`/case-service/cp/mortgage-cases`, { params: request });
  }

  HTTP_CP_ListSmartSearchCases(request?: undefined): Observable<HTTP_CP_ListSmartSearchCases_Response> {
    return this.httpClient.get(`/case-service/cp/smart-search-cases`, { params: request });
  }

  HTTP_CP_UpdateHelpCase(request: HTTP_CP_UpdateHelpCase_Request): Observable<HTTP_CP_HelpCase> {
    return this.httpClient.put(`/case-service/cp/help-cases/${request.helpCaseId}`, request);
  }

  HTTP_CP_UpdateMortgageCase(request: HTTP_CP_UpdateMortgageCase_Request): Observable<HTTP_CP_MortgageCase> {
    return this.httpClient.put(`/case-service/cp/mortgage-cases/one`, request);
  }

  HTTP_CP_UpdateSmartSearchCase(request: HTTP_CP_UpdateSmartSearchCase_Request): Observable<HTTP_CP_SmartSearchCase> {
    return this.httpClient.put(`/case-service/cp/smart-search-cases/${request.smartSearchCaseId}`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
